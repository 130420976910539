import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-power-list"></a><h2>Psionic Power List
    </h2>
    <a id="psion-wilder-powers"></a><h3>PSION/WILDER POWERS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="first"></a><a id="first-level-psion-wilder-powers"></a><h5>1ST-LEVEL PSION/WILDER POWERS</h5>
    <table style={{
      "width": "75%"
    }} className="spell-list" border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#astral-traveler" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Traveler:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Enable yourself or
another to join an astral caravan-enabled trip.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#attraction" style={{
              "color": "rgb(87, 158, 182)"
            }}>Attraction<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Subject has an
attraction you specify.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#bolt" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bolt<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You create a few
enhanced short-lived bolts, arrows, or bullets.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#call-to-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Call to Mind:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Gain additional
Knowledge check with +4 competence bonus.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#catfall" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catfall<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Instantly save yourself
from a fall.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#conceal-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Conceal Thoughts:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You conceal your
motives.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#control-flames" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Flames<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Take control of nearby
open flame.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#control-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Light:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Adjust ambient light
levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#create-sound" style={{
              "color": "rgb(87, 158, 182)"
            }}>Create Sound:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Create the sound you
desire.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#crystal-shard" style={{
              "color": "rgb(87, 158, 182)"
            }}>Crystal Shard<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Ranged touch attack for
1d6 points of piercing damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#daze-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Daze, Psionic<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Humanoid creature of 4
HD or less loses next action.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#deceleration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Deceleration<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Target&rsquo;s speed is
halved.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#deja-vu" style={{
              "color": "rgb(87, 158, 182)"
            }}>D&eacute;j&agrave; Vu<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Your target repeats his
last action.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#demoralize" style={{
              "color": "rgb(87, 158, 182)"
            }}>Demoralize<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Enemies become shaken.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Psionics:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You detect the presence
of psionics.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#disable" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disable<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Subjects incorrectly
believe they are disabled.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dissipating-touch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissipating Touch<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Touch deals 1d6 damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#distract" style={{
              "color": "rgb(87, 158, 182)"
            }}>Distract:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Target gets -4 bonus on
Listen, Search, Sense Motive, and Spot checks.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ecto-protection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ecto Protection<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>An astral construct
gains bonus against dismiss ectoplasm.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#empathy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathy<sup>A</sup>:</a>
          </td>
          <td style={{
            "verticalAlign": "middle"
          }}>You know the
subject&rsquo;s
surface emotions.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empty-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empty Mind<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You gain +2 on Will
saves until your next action.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-ray" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Ray<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Deal 1d6 energy (cold,
electricity, fire, or sonic) damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#entangling-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Entangling Ectoplasm:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You entangle a foe in
sticky goo. </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#far-hand" style={{
              "color": "rgb(87, 158, 182)"
            }}>Far Hand<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Move small objects at a
limited distance. </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#float" style={{
              "color": "rgb(87, 158, 182)"
            }}>Float:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You buoy yourself in
water or other liquid.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#force-screen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Force Screen<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Invisible disc provides
+4 shield bonus to AC.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#grease-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Grease, Psionic:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Makes
10-ft. square or one object slippery.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#hammer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hammer<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Melee touch attack
deals 1d8/round.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#inertial-armor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Armor<sup>A</sup>:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Tangible
field of force provides you with +4 armor bonus to AC.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#know-direction-and-location" style={{
              "color": "rgb(87, 158, 182)"
            }}>Know Direction and Location:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You discover where you
are and what direction you face.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#matter-agitation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Matter Agitation:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>You
heat a creature or object.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#mind-thrust" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Thrust<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Deal 1d10 damage.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#missive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Missive<sup>A</sup>:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Send
a one-way telepathic message to subject.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#my-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>My Light<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Your eyes emit 20-ft.
cone of light.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#precognition-defensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Defensive<sup>A</sup>:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Gain
+1 insight bonus to AC and saving throws.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersGtoP.html#precognition-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Offensive<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Gain +1 insight bonus
on your attack rolls.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersGtoP.html#prescience-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prescience, Offensive<sup>A</sup>:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Gain
+2 insight bonus on your damage rolls.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#sense-link" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Link<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You sense what the
subject senses (single sense).</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersQtoW.html#skate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Skate:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Subject
slides skillfully along the ground.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#synesthete" style={{
              "color": "rgb(87, 158, 182)"
            }}>Synesthete:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>You receive one kind of
sense when another sense is stimulated.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersQtoW.html#telempathic-projection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telempathic Projection:</a></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Alter
the subject&rsquo;s mood.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#vigor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vigor<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "middle"
          }}>Gain 5 temporary hit
points.</td>
        </tr>
      </tbody>
    </table>
    <a id="second"></a><a id="second-level-psion-wilder-powers"></a><h5>2ND-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#bestow-power" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bestow Power<sup>A</sup>:</a></td>
          <td>Subject receives 2 power points.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#biofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Biofeedback<sup>A</sup>:</a></td>
          <td>Gain damage reduction 2/-.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#body-equilibrium" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Equilibrium:</a></td>
          <td>You can walk on nonsolid surfaces.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#cloud-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Cloud Mind:</a></td>
          <td>You erase knowledge of your presence from
target&rsquo;s mind.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#concealing-amorpha" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha:</a></td>
          <td>Quasi-real membrane grants you concealment.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#concussion-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concussion Blast<sup>A</sup>:</a></td>
          <td>Deal 1d6 force damage to target.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#control-sound" style={{
              "color": "rgb(87, 158, 182)"
            }}>Control Sound:</a></td>
          <td>Create very specific sounds.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-hostile-intent" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Hostile Intent:</a></td>
          <td>You can detect hostile creatures within 30 ft. of you.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ego-whip" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ego Whip<sup>A</sup>:</a></td>
          <td>Deal 1d4 Cha damage and daze for 1 round.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#elfsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Elfsight:</a></td>
          <td>Gain low-light vision, +2 bonus on Search and Spot
checks, and notice secret doors.
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-adaptation-specified" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation, Specified<sup>A</sup>:</a></td>
          <td>Gain resistance 10 against one energy type.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-push" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Push<sup>A</sup>:</a></td>
          <td>Deal 2d6 damage and knock subject back.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-stun" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Stun<sup>A</sup>:</a></td>
          <td>Deal 1d6 damage and stun target if it fails both saves.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#feat-leech" style={{
              "color": "rgb(87, 158, 182)"
            }}>Feat Leech<sup>A</sup>:</a></td>
          <td>Borrow another&rsquo;s psionic or metapsionic feats.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#id-insinuation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Id Insinuation<sup>A</sup>:</a></td>
          <td>Swift tendrils of thought disrupt and confuse your
target.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#identify-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Identify, Psionic:</a></td>
          <td>Learn the properties of a psionic item.
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#inflict-pain" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inflict Pain<sup>A</sup>:</a></td>
          <td>Telepathic stab gives your foe -4 on attack rolls, or
-2 if he makes the save.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#knock-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Knock, Psionic:</a></td>
          <td>Opens locked or psionically sealed door.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#levitate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Levitate, Psionic:</a></td>
          <td>You move up and down via mental
support.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mental-disruption" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mental Disruption<sup>A</sup>:</a></td>
          <td>Daze creatures within 10 feet for 1 round.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#missive-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Missive, Mass<sup>A</sup>:</a></td>
          <td>You send a one-way telepathic message to an area.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psionic-lock" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Lock:</a></td>
          <td>Secure a door, chest, or portal.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#recall-agony" style={{
              "color": "rgb(87, 158, 182)"
            }}>Recall Agony<sup>A</sup>:</a></td>
          <td>Foe takes 2d6 damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#sense-link-forced" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Link, Forced:</a></td>
          <td>Sense what subject senses.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#share-pain" style={{
              "color": "rgb(87, 158, 182)"
            }}>Share Pain:</a></td>
          <td>Willing subject takes some of your damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#sustenance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sustenance:</a></td>
          <td>Go without food and water for one day.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#swarm-of-crystals" style={{
              "color": "rgb(87, 158, 182)"
            }}>Swarm of Crystals<sup>A</sup>:</a></td>
          <td>Crystal shards are sprayed forth doing 3d4 slashing
damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#thought-shield" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thought Shield<sup>A</sup>:</a></td>
          <td>Gain PR 13 against mind-affecting powers.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#tongues-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tongues, Psionic:</a></td>
          <td>You can communicate with intelligent creatures.</td>
        </tr>
      </tbody>
    </table>
    <a id="third"></a><a id="third-level-psion-wilder-powers"></a><h5>3RD-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#body-adjustment" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Adjustment<sup>A</sup>:</a></td>
          <td>You heal 1d12 damage.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#body-purification" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Purification<sup>A</sup>:</a></td>
          <td>You restore 2 points of ability damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#danger-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Danger Sense<sup>A</sup>:</a></td>
          <td>You gain +4 bonus against traps.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#darkvision-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Darkvision, Psionic:</a></td>
          <td>See 60 ft. in total darkness.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dismiss-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dismiss Ectoplasm:</a></td>
          <td>Dissipates ectoplasmic targets and effects.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dispel-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dispel Psionics<sup>A</sup>:</a></td>
          <td>Cancels psionic powers and effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-bolt" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Bolt<sup>A</sup>:</a></td>
          <td>Deal 5d6 energy damage in 120-ft. line.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Burst<sup>A</sup>:</a></td>
          <td>Deal 5d6 energy damage in 40-ft. burst.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-retort" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Retort<sup>A</sup>:</a></td>
          <td>Ectoburst of energy automatically targets your attacker
for 4d6 damage once each round.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-wall" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Wall:</a></td>
          <td>Create wall of your chosen energy type.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#eradicate-invisibility" style={{
              "color": "rgb(87, 158, 182)"
            }}>Eradicate Invisibility<sup>A</sup>:</a></td>
          <td>Negate invisibility in 50-ft. burst.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#keen-edge-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Keen Edge, Psionic:</a></td>
          <td>Doubles normal weapon&rsquo;s threat range.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mental-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mental Barrier<sup>A</sup>:</a></td>
          <td>Gain +4 deflection bonus to AC until your next action.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-trap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Trap<sup>A</sup>:</a></td>
          <td>Drain 1d6 power points from anyone who attacks you with
a telepathy power.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psionic-blast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Blast:</a></td>
          <td>Stun creatures in 30-ft. cone for 1 round.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#share-pain-forced" style={{
              "color": "rgb(87, 158, 182)"
            }}>Share Pain, Forced<sup>A</sup>:</a></td>
          <td>Unwilling subject takes some of your damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#solicit-psicrystal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Solicit Psicrystal<sup>A</sup>:</a></td>
          <td>Your psicrystal takes over your concentration power.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#telekinetic-force" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Force<sup>A</sup>:</a></td>
          <td>Move an object with the sustained force of your mind.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#telekinetic-thrust" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Thrust<sup>A</sup>:</a></td>
          <td>Hurl objects with the force of your mind.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#time-hop" style={{
              "color": "rgb(87, 158, 182)"
            }}>Time Hop<sup>A</sup>:</a></td>
          <td>Subject hops forward in time 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#touchsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Touchsight<sup>A</sup>:</a></td>
          <td>Your telekinetic field tells you where everything is.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#ubiquitous-vision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ubiquitous Vision:</a></td>
          <td>You have all-around vision.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth"></a><a id="fourth-level-psion-wilder-powers"></a><h5>4TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#aura-sight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aura Sight<sup>A</sup>:</a></td>
          <td>Reveals creatures, objects, powers, or spells of
selected alignment axis.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#correspond" style={{
              "color": "rgb(87, 158, 182)"
            }}>Correspond:</a></td>
          <td>Hold mental conversation with another creature at any
distance.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#death-urge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Death Urge<sup>A</sup>:</a></td>
          <td>Implant a self-destructive compulsion.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-remote-viewing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Remote Viewing:</a></td>
          <td>You know when others spy on you remotely.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dimension-door-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Door, Psionic:</a></td>
          <td>Teleports you short distance.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#divination-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Divination, Psionic</a></td>
          <td>Provides useful advice for specific proposed action.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empathic-feedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Feedback<sup>A</sup>:</a></td>
          <td>When you are hit in melee, your attacker takes damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-adaptation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation<sup>A</sup>:</a></td>
          <td>Your body converts energy to harmless light.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#freedom-of-movement-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Freedom of Movement, Psionic:</a></td>
          <td>You cannot be held or otherwise rendered immobile.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#intellect-fortress" style={{
              "color": "rgb(87, 158, 182)"
            }}>Intellect Fortress<sup>A</sup>:</a></td>
          <td>Those inside fortress take only half damage from all
powers and psi-like abilities until your next action.
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mindwipe" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mindwipe<sup>A</sup>:</a></td>
          <td>Subject&rsquo;s recent experiences wiped away,
bestowing
negative levels.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#personality-parasite" style={{
              "color": "rgb(87, 158, 182)"
            }}>Personality Parasite:</a></td>
          <td>Subject&rsquo;s mind calves self-antagonistic splinter
personality for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#power-leech" style={{
              "color": "rgb(87, 158, 182)"
            }}>Power Leech:</a></td>
          <td>Drain 1d6 power points/round while you maintain
concentration; you gain 1/round.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psychic-reformation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Reformation<sup>X</sup>:</a></td>
          <td>Subject can choose skills, feats, and powers anew for
previous levels.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#telekinetic-maneuver" style={{
              "color": "rgb(87, 158, 182)"
            }}>Telekinetic Maneuver<sup>A</sup>:</a></td>
          <td>Telekinetically bull rush, disarm, grapple, or trip
your target.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#trace-teleport" style={{
              "color": "rgb(87, 158, 182)"
            }}>Trace Teleport<sup>A</sup>:</a></td>
          <td>Learn destination of subject&rsquo;s teleport.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#wall-of-ectoplasm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Wall of Ectoplasm:</a></td>
          <td>You create a protective barrier.</td>
        </tr>
      </tbody>
    </table>
    <a id="fifth"></a><a id="fifth-level-psion-wilder-powers"></a><h5>5TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#adapt-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Adapt Body:</a></td>
          <td>Your body automatically adapts to hostile environments.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#catapsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catapsi<sup>A</sup>:</a></td>
          <td>Psychic static inhibits power manifestation.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#ectoplasmic-shambler" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Shambler:</a></td>
          <td>Foglike predator deals 1 point of damage/two levels
each round to an area.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#incarnate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Incarnate<sup>X</sup>:</a></td>
          <td>Make some powers permanent.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#leech-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Leech Field<sup>A</sup>:</a></td>
          <td>Leech power points each time you make a saving throw.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#major-creation-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Major Creation, Psionic:</a></td>
          <td>As psionic minor creation, plus stone and metal.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#plane-shift-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Plane Shift, Psionic:</a></td>
          <td>Travel to other planes.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#power-resistance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Power Resistance:</a></td>
          <td>Grant PR equal to 12 + level.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychic-crush" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Crush<sup>A</sup>:</a></td>
          <td>Brutally crush subject&rsquo;s mental essence, reducing
subject to -1 hit points.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#shatter-mind-blank" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shatter Mind Blank:</a></td>
          <td>Cancels target&rsquo;s mind blank effect.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#tower-of-iron-will" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tower of Iron Will<sup>A</sup>:</a></td>
          <td>Grant PR 19 against mind-affecting powers to all
creatures within 10 ft. until your next turn.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#true-seeing-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Seeing, Psionic<sup>A</sup>:</a></td>
          <td>See all things as they really are.</td>
        </tr>
      </tbody>
    </table>
    <a id="sixth"></a><a id="sixth-level-psion-wilder-powers"></a><h5>6TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#aura-alteration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aura Alteration<sup>A</sup>:</a></td>
          <td>Repairs psyche or makes subject seem to be something it
is not.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#breath-of-the-black-dragon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Breath of the Black Dragon<sup>A</sup>:</a></td>
          <td>Breathe acid for 11d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#cloud-mind-mass" style={{
              "color": "rgb(87, 158, 182)"
            }}>Cloud Mind, Mass</a></td>
          <td>Erase knowledge of your presence from the minds of one
creature/level.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#contingency-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Contingency, Psionic<sup>X</sup>:</a></td>
          <td>Sets trigger condition for another power.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#co-opt-concentration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Co-Opt Concentration:</a></td>
          <td>Take control of foe&rsquo;s concentration power.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#disintegrate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disintegrate, Psionic<sup>A</sup>:</a></td>
          <td>Turn one creature or object to dust.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#fuse-flesh" style={{
              "color": "rgb(87, 158, 182)"
            }}>Fuse Flesh<sup>A</sup>:</a></td>
          <td>Fuse subject&rsquo;s flesh, creating a helpless mass.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#overland-flight-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Overland Flight, Psionic:</a></td>
          <td>You fly at a speed of 40 ft. and can hustle over long
distances.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#remote-view-trap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Remote View Trap:</a></td>
          <td>Deal 8d6 points electricity damage to those who seek to
view you at a distance.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#retrieve" style={{
              "color": "rgb(87, 158, 182)"
            }}>Retrieve<sup>A</sup>:</a></td>
          <td>Teleport to your hand an item you can see.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#suspend-life" style={{
              "color": "rgb(87, 158, 182)"
            }}>Suspend Life:</a></td>
          <td>Put yourself in a state akin to suspended animation.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#temporal-acceleration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Temporal Acceleration<sup>A</sup>:</a></td>
          <td>Your time frame accelerates for 1 round.</td>
        </tr>
      </tbody>
    </table>
    <a id="seventh"></a><a id="seventh-level-psion-wilder-powers"></a><h5>7TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#decerebrate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Decerebrate:</a></td>
          <td>Remove portion of subject&rsquo;s brain stem.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#divert-teleport" style={{
              "color": "rgb(87, 158, 182)"
            }}>Divert Teleport:</a></td>
          <td>Choose destination for another&rsquo;s teleport.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-conversion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Conversion:</a></td>
          <td>Offensively channel energy you&rsquo;ve absorbed.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-wave" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Wave<sup>A</sup>:</a></td>
          <td>Deal 13d4 damage of your chosen energy type in 120-ft.
cone.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#evade-burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Evade Burst<sup>A</sup>:</a></td>
          <td>You take no damage from a burst on a successful Reflex
save.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#insanity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Insanity<sup>A</sup>:</a></td>
          <td>Subject is permanently confused.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#mind-blank-personal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Blank, Personal:</a></td>
          <td>You are immune to scrying and mental effects.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#moment-of-prescience-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Moment of Prescience, Psionic:</a></td>
          <td>You gain insight bonus on single attack roll, check, or
save.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#oak-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Oak Body<sup>A</sup>:</a></td>
          <td>Your body becomes as hard as oak.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#phase-door-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Phase Door, Psionic:</a></td>
          <td>Invisible passage through wood or stone.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#sequester-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sequester, Psionic<sup>X</sup>:</a></td>
          <td>Subject invisible to sight and remote viewing; renders
subject comatose.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#ultrablast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ultrablast<sup>A</sup>:</a></td>
          <td>Deal 13d6 damage in 15-ft. radius.</td>
        </tr>
      </tbody>
    </table>
    <a id="eigth"></a><a id="eigth-level-psion-wilder-powers"></a><h5>8TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#bend-reality" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bend Reality<sup>X</sup>:</a></td>
          <td>Alters reality within power limits.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#iron-body-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Iron Body, Psionic:</a></td>
          <td>Your body becomes living iron.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#matter-manipulation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Matter Manipulation<sup>X</sup>:</a></td>
          <td>Increase or decrease an object&rsquo;s base hardness by
5.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-blank-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Blank, Psionic:</a></td>
          <td>Subject immune to mental/emotional effects, scrying,
and remote viewing.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#recall-death" style={{
              "color": "rgb(87, 158, 182)"
            }}>Recall Death:</a></td>
          <td>Subject dies or takes 5d6 damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#shadow-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shadow Body:</a></td>
          <td>You become a living shadow (not the creature).</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#teleport-psionic-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Teleport, Psionic Greater:</a></td>
          <td>As psionic teleport, but no range limit and no
off-target arrival.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#true-metabolism" style={{
              "color": "rgb(87, 158, 182)"
            }}>True Metabolism:</a></td>
          <td>You regenerate 10 hit points/round.</td>
        </tr>
      </tbody>
    </table>
    <a id="ninth"></a><a id="ninth-level-psion-wilder-powers"></a><h5>9TH-LEVEL PSION/WILDER POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#affinity-field" style={{
              "color": "rgb(87, 158, 182)"
            }}>Affinity Field:</a></td>
          <td>Effects that affect you also affect others.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#apopsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Apopsi<sup>X</sup>:</a></td>
          <td>You delete target&rsquo;s psionic powers.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#assimilate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Assimilate:</a></td>
          <td>Incorporate creature into your own body.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#etherealness-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Etherealness, Psionic:</a></td>
          <td>Become ethereal for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#microcosm" style={{
              "color": "rgb(87, 158, 182)"
            }}>Microcosm<sup>A</sup>:</a></td>
          <td>Creature or creature lives forevermore in world of his
own imagination.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#reality-revision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Reality Revision<sup>X</sup>:</a></td>
          <td>As bend reality, but fewer limits.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#timeless-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Timeless Body:</a></td>
          <td>Ignore all harmful, and helpful, effects for 1 round.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      